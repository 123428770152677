import React from 'react';

const ToS: React.FC = () => (
  <div className='page'>
    <h1>Terms of Service</h1>
    <p><em>Last updated: October 11, 2024</em></p>

    <p>Welcome to Behavior Monster! These Terms of Service ("Terms") govern your use of our mobile application ("App") and any related services provided by us.</p>

    <h2>1. Acceptance of Terms</h2>
    <p>By downloading, accessing, or using Behavior Monster, you agree to be bound by these Terms. If you do not agree with these Terms, please do not use the App.</p>

    <h2>2. Changes to Terms</h2>
    <p>We reserve the right to modify these Terms at any time. We will notify you of any changes by updating the date at the top of this page. Continued use of the App after any such changes constitutes your acceptance of the new Terms.</p>

    <h2>3. Use of the App</h2>
    <ul>
        <li>The App is intended for parents and guardians to help children develop good habits.</li>
        <li>Users must be at least 18 years old or have the consent of a parent or guardian.</li>
        <li>You agree to use the App only for lawful purposes and in accordance with these Terms.</li>
    </ul>

    <h2>4. User Accounts</h2>
    <ul>
        <li>To access some features of the App, you may need to create an account.</li>
        <li>You are responsible for maintaining the confidentiality of your account and password.</li>
        <li>You agree to notify us immediately of any unauthorized use of your account.</li>
    </ul>

    <h2>5. Privacy Policy</h2>
    <p>Your privacy is important to us. Please review our Privacy Policy, which explains how we collect, use, and protect your personal information. By using the App, you agree to the terms of our Privacy Policy.</p>

    <h2>6. Prohibited Conduct</h2>
    <p>When using the App, you agree not to:</p>
    <ul>
        <li>Use the App for any illegal or unauthorized purpose.</li>
        <li>Modify, adapt, or hack the App.</li>
        <li>Transmit any viruses or other harmful code.</li>
        <li>Interfere with or disrupt the App’s servers or networks.</li>
    </ul>

    <h2>7. Termination</h2>
    <p>We may suspend or terminate your access to the App at any time, without prior notice, for violating these Terms or for any other reason at our sole discretion.</p>

    <h2>8. Limitation of Liability</h2>
    <p>The App is provided on an "as-is" basis. We do not guarantee that the App will be error-free or uninterrupted. We are not responsible for any direct, indirect, incidental, or consequential damages or data loss resulting from your use of the App.</p>

    <h2>9. Governing Law</h2>
    <p>These Terms are governed by and construed in accordance with the laws of Spain. Any disputes arising under or related to these Terms shall be resolved in the courts of Spain.</p>

    <h2>10. Contact Us</h2>
    <p>If you have any questions or concerns about these Terms, please contact us at <a href="mailto:support@behaviormonster.com">support@behaviormonster.com</a>.</p>
  </div>
);

export default ToS;
