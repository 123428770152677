import React from 'react';

const About: React.FC = () => (
<div className='page'>
<h1>About Us</h1>
    <div className="content">
        <p>Ever feel like nothing works with your kids, and you're just trying to keep the peace?</p>
        
        <p><strong>That was us</strong> - stuck at home during COVID with two little ones (ages 1 and 4), juggling work and parenting in one chaotic space. <strong>It was exhausting</strong>. Our oldest, Alex, suddenly wasn’t in the mood to collaborate either. Asking him to help led to endless frustration and dreaded time-outs, which only made things worse.</p>
        
        <img src="/crazyfamily.jpg" alt="Family getting crazy" className="about-image img-small" />
        
        <p>Then one day, I tried something new. I drew a little monster on a piece of paper, divided it into four sections, and gave Alex a simple deal: every time he finished a task, he could color one part. Finish the whole monster, and he’d earn his favorite show. To my surprise, he was thrilled! Tasks became something he wanted to do, and before long, he was even drawing his own monsters.</p>
        
        <img src="/handmade_monsters.jpg" alt="Collage of hand-drawn monsters" className="about-image img-medium" />

        <p>That little game completely changed our days. Instead of punishment, we had a fun, motivating way to encourage him. And on those rare 'uh-oh' moments? No problem, he just had to color over part of the monster again.</p>

        <p>Seeing how this simple game transformed our days, I thought, why not make it into an app? With Alex on board as “chief monster artist,” Behavior Monster came to life: a fun, simple way to build good habits and make parenting just a bit easier. Now we’re happily using the app with our youngest, Chris, too, and sometimes we even catch him making his bed by himself just for the chance to color in a section.</p>

        <img src="/bed.jpg" alt="Little kid making his bed" className="about-image img-small" />
        
        <p>We’re thrilled to share Behavior Monster with other families! If you’re looking for a playful, positive way to bring out the best in your kids, welcome aboard. Let’s color some monsters and build good habits together.</p>
        
        <p>And if Behavior Monster is making a difference in your life, please let us know! We’d love to hear your story.</p>
        
    </div>    
</div>
);

export default About;