import React from 'react';

const Privacy: React.FC = () => (
  <div className='page'>
    <h1>Privacy Policy</h1>
    <p><strong>Effective Date: 4 Sept 2024</strong></p>

    <p>Welcome to BehaviorMonster! We are committed to protecting your privacy and ensuring you have a positive experience on our app. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your information when you use BehaviorMonster.</p>

    <h2>1. Information We Collect</h2>
    <ul>
        <li><strong>User Provided Information</strong>: When you create an account, we collect an anonimized identifier to keep track of your account. Additionally, you can create profiles for each child including their name and gender.</li>
        <li><strong>Generated Information</strong>: As you use the app, we collect data on task completion and reward allocation to manage the app’s functionality.</li>
        <li><strong>Usage Information</strong>: We collect information about how you interact with the app, including buttons clicked, navigation paths, and other user actions. This data helps us understand how the app is used and improve its functionality.</li>
    </ul>

    <h2>2. How We Use Your Information</h2>
    <p>We use the information we collect to provide, operate, and maintain our services, as well as to develop new features and functionality within BehaviorMonster. Usage information is specifically used to enhance the user experience and optimize app performance.</p>

    <h2>3. Sharing of Information</h2>
    <p>We do not share your personal information with any third parties, except as necessary to provide our services, perform usage analysis or as required by law.</p>

    <h2>4. Data Storage and International Transfers</h2>
    <p>Your data is stored on Google Firestore, which may store data in servers located outside of your country of residence. We rely on Google Firestore’s robust security measures to protect your data.</p>

    <h2>5. Data Security</h2>
    <p>We implement robust security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. Access to personal information is strictly limited to the user who created that information. Only authenticated users who have entered the correct credentials can access their account and the information associated with it. Our IT team at BehaviorMonster may access the data to resolve issues or delete data upon user request. We utilize Google Firestore's security rules to ensure that each user's data can only be accessed by them, maintaining strict privacy and integrity of your data. These security practices are designed to protect your personal information, ensuring that it is handled securely and in accordance with this Privacy Policy.</p>

    <h2>6. Your Rights</h2>
    <p>You may request access, correction, or deletion of your personal information by contacting us via email. We will verify your identity before processing your request.</p>

    <h2>7. Use of Cookies and Similar Technologies</h2>
    <p>BehaviorMonster uses Firebase Analytics, which may deploy cookies or similar technologies to analyze how users interact with our app.</p>

    <h2>8. Children's Privacy</h2>
    <p>Our app is intended for use by parents of children aged 4 to 8 years. We do not knowingly collect personal information from children under the age of 13. If we become aware that we have inadvertently collected personal information from a child under the age of 13 without parental consent, we will take steps to delete such information from our records.</p>

    <h2>9. Changes to This Privacy Policy</h2>
    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>

    <h2>10. Contact Us</h2>
    <p>If you have any questions about this Privacy Policy, please contact us at: support@behaviormonster.com</p>

  </div>
);

export default Privacy;
